import React, { useState } from 'react'
import Layout from "../components/layout"
import { Button } from "react-bootstrap"
import SEO from "../components/seo"
import AbstractMedia from '../components/abstractMedia/AbstractMedia'
import Blocks from "./blocks/Blocks"

const ArticleList = ({ pageContext, location }) => {
    let {
        pages,
        vieEntreprise,
        technique,
        clients,
        media,
        allArticles
    } = pageContext

    let page = pages.filter(page => page.url === "/blog");
    page = page[0];

    const categories = [
        { id: 'category-1', value: 'Toutes les catégories' },
        { id: 'category-2', value: 'Vie de l\'entreprise' },
        { id: 'category-3', value: 'Technique' },
        { id: 'category-4', value: 'Clients' }
    ];

    const [externalLink, setExternalLink] = useState(false)
    const [articlesToShow, setArticlesToShow] = useState(allArticles);
    const [activeButton, setActiveButton] = useState('category-1');

    if (location.state && location.state.category) {
        setExternalLink(true)
    }

    if (externalLink) {
        setArticlesToShow(getArray(location.state.category))
        setActiveButton(getId(location.state.category))
        location.state.category = null
        setExternalLink(false)
    }

    function getArray(newCategory) {
        switch (newCategory) {
            case 'Vie de l\'entreprise':
                return vieEntreprise
            case 'Technique':
                return technique
            case 'Clients':
                return clients
            default:
                return allArticles
        }
    }

    function getId(newCategory) {
        const temp = categories.filter(category => category.value === newCategory)
        return temp[0].id
    }

    function changeFilter(newCategory) {
        setArticlesToShow(getArray(newCategory.value))
        setActiveButton(newCategory.id);
    }

    return (
        <Layout ariane={page.ariane}>
            <SEO titleSEO={page.titleSEO} />
            <Blocks page={page} media={media} />
            <div className="article-list">
                <div className="container">
                    <div className="title-category">
                        <h2>Catégories</h2>
                    </div>
                    <div className="button-list">
                        {categories.map(category => (
                            <Button className="button-category" active={activeButton === category.id} key={category.id} onClick={() => changeFilter(category)}>{category.value}</Button>
                        ))}
                    </div>
                    <div className="d-flex flex-column">
                        {articlesToShow.map((article, index) => (
                            <div key={article.id} className="row">
                                <div className="col-md-6 my-auto">
                                    {media && (<AbstractMedia media={media} abstractMedia={article.media} title={(article.title && article.title !== "") && article.title} className="img-fluid d-block mx-auto" />)}
                                </div>
                                <div className={`col-md-6 ${index % 2 === 0 ? `order-last` : `order-first`}`}>
                                    <div className="roof">
                                        {article.decoration && (<AbstractMedia media={media} abstractMedia={article.decoration} className="top" />)}
                                        <div className="article-content">
                                            <div className="date">{article.date.split("-").reverse().join("/")}</div>
                                            <h2>{article.title}</h2>
                                            <div className="content" dangerouslySetInnerHTML={{ __html: article.description }} />
                                            <a href={`/blog/${article.url}`} className="btn-green">Lire la suite de l'article</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default ArticleList